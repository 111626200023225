import React, { Component } from "react";
import "./../../../Common.css";
import axios from "axios";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
  UserInfo,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import { BIZ_SERVER_ADDRESS } from "./../../../config.json";
import {
  BoardAttribute,
  BoardContent,
  BoardContentQuiz,
  DailyQuiz,
} from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil, {
  TEMPLATE_REG_DOUBLE_BRACKET,
} from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { loadImageBase64, loadImageUrlBase64 } from "../../../utils/ImageUtil";
import ImageViewNSelect, { ImageSize } from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import BoardSummary from "../../../components/templates/BoardSummary";
import { Flex, Static } from "../../../components/atoms/Layout";
import { MY_ADDRESS } from "../../../config.json";
import Text from "../../../components/atoms/Text";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import Separator from "../../../components/atoms/Separator";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import Viewer from "../../../pages/Viewer";
import BoardQuizView from "../../../components/templates/BoardQuizView";
import Input from "../../../components/atoms/Input";
import { on } from "cluster";
import { getDate } from "date-fns";
import Icon from "../../../components/atoms/Icon";
import { ScheduleMessageInfo } from "../../../models/Model.User.Message";

const AdminAPI = {
  DAILY_QUIZ_POST: {
    method: "POST",
    path: "/admin/quiz/daily",
    contentType: "application/json",
  },
  DAILY_QUIZ_PUT: {
    method: "PUT",
    path: "/admin/quiz/daily",
    contentType: "application/json",
  },
  DAILY_QUIZ_GET: {
    method: "GET",
    path: "/admin/quiz/daily",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    email: ScheduleMessageInfo;
    me: UserInfo;
    onCancel?: () => void;
  };

type State = {
  applyUserInfo: boolean;
};

class EmailPreview extends Component<Props, State> {
  state: State = { applyUserInfo: true };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {}

  componentWillUnmount(): void {}

  componentDidUpdate(prevProps, nextState) {}

  onSendTestEmail = async () => {
    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">테스트 이메일을 발송하시겠습니까?</span>
        </div>
      ),
      body: (
        <div className="common-container">
          수신인을 입력해주세요.(다양한 서비스 계정에게 보내세요)
        </div>
      ),
      needTextArea: "true",
      defaultText:
        getGlobal("AdminTestEmailRecipients", true) ||
        "oscar@ymyd.co.kr\nyoni230@gmail.com\nyoni230@naver.com",
      onDone: this.doSendTestEmail,
      doneText: "네",
    });
  };

  doSendTestEmail = async (recipients: string) => {
    // split recipients with \n , ; and filter out empty strings
    const recipientList = recipients
      .split(/[\n,;]/)
      .map((r) => r.trim())
      .filter((r) => r.length > 0);

    if (recipientList.length == 0) return;

    let email: ScheduleMessageInfo = { ...this.props.email, to: recipientList };
    email.title = StringUtil.createStringFromTemplate(
      email.title,
      this.props.me,
      TEMPLATE_REG_DOUBLE_BRACKET
    );

    email.body = StringUtil.createStringFromTemplate(
      email.body,
      { ...this.props.me, title: email.title },
      TEMPLATE_REG_DOUBLE_BRACKET
    );

    email.title = "[TEST] " + email.title;

    delete email["scheduledAtWarning"];
    delete email["eventSource"];

    await axios.post(`${BIZ_SERVER_ADDRESS}/mailer/send/test`, email, {
      headers: {
        Accept: "application/json",
        //   'Content-Type': api.contentType,
        Origin: "",
        Authorization: "Bearer " + getGlobal(GlobalKey.TOKEN, true),
      },
    });
  };

  render() {
    log(LogLevel.UI_ACTION, "EmailPreview render", this.state);

    let title = this.props.email.title;
    let body = this.props.email.body;

    if (this.state.applyUserInfo) {
      title = StringUtil.createStringFromTemplate(
        title,
        this.props.me,
        TEMPLATE_REG_DOUBLE_BRACKET
      );
      body = StringUtil.createStringFromTemplate(
        body,
        { ...this.props.me, title },
        TEMPLATE_REG_DOUBLE_BRACKET
      );
    }

    return (
      <div className="admin-container">
        {this.props.onCancel && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start" class="common-container">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onCancel}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel class="common-header-title common-flex-grow">
                  이메일 미리보기
                </IonLabel>
                <ToggleButton
                  onClick={() => {
                    this.setState({ applyUserInfo: !this.state.applyUserInfo });
                  }}
                  size={"Small"}
                  type={"Text"}
                  variant={"Tinted"}
                  color={"Skyblue"}
                  active={this.state.applyUserInfo}
                >
                  회원정보 적용
                </ToggleButton>
                <Button
                  onClick={this.onSendTestEmail}
                  size={"Small"}
                  type={"Text"}
                  variant={"Outlined"}
                  color={"Primary"}
                >
                  테스트 발송
                </Button>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}

        <Flex
          direction="column"
          gap="10px"
          customStyle={{ padding: "0px 20px", height: "calc(100% - 50px)" }}
        >
          <Text textType="LargeTitle3" color={COLOR_SYSTEM.get("Gray")[900]}>
            {title}
          </Text>
          <iframe
            srcDoc={body}
            style={{ width: "100%", height: "100%", flexGrow: 1 }}
          />
        </Flex>
      </div>
    );

    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailPreview);
